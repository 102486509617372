<template>
  <div class="approval-report-wrapper flex">
    <d-search :searchData="searchData" @searchEvent="searchList(1)" @exportEvent="exportList" @resetEvent="reset">
      <time-search ref="timeSearch" :param.sync="searchData.params" :options="$map.getter('BPMtimeTags')"  :default-select="7"></time-search>
    </d-search>
    <div class="inline-flex contentBox">
      <d-table :tableData="tableData" :columnData="columnData" class="contentTable"></d-table>
    </div>
    <d-paging :pager="pager" @change="searchList"></d-paging>
  </div>
</template>

<script>
import TimeSearch from "@/pages/statement/approval-report/components/time-search";
import DSearch from "@/components/d-search";
import DTable from "@/components/d-table";
import DPaging from "@/components/d-paging";
import {onDownload} from "@/utils/utils";
import {removeLeaf} from "@/utils/utils";
export default {
  name: "department-statistics",
  components: {DPaging, DTable, DSearch, TimeSearch},
  data() {
    return {
      departmentLists: [],
      searchData: {
        searchItem: [
          {label: '部门', value: 'deptId', type: 'linkageSelect', options: [],filterable:true,
            optionProps:{
              label:"deptName",value:"id",emitPath:false,children:"children"
            }
          }
        ],
        params: {
          deptId: '',
          startTime: '',
          endTime: ''
        },
        searchBtn: {
          queryBtn: true,
          resetBtn: true,
          exportBtn: true
        }
      },
      tableData: [],
      columnData: [
        {label: '审批部门', prop: 'deptName'},
        {label: '审批数量', prop: 'totalNum'},
        {label: '平均耗时', prop: 'avgTimeConsuming'},
        {label: '超时数量', prop: 'timeoutTotal'},
        {label: '超时率', prop: 'timeoutRate'}
      ],
      pager: {
        count: 0,
        page: 1,
        rows: 10,
      }
    }
  },
  mounted(){
    this.getDepartmentList();
    this.searchList();
  },
  methods:{
    // 获取审批部门
    getDepartmentList(){
      this.$api.sysapi.marketPeopleTree().then(({data}) => {
        this.departmentLists = data.children.map(e=>(delete e.children,e)).filter(e=>(e.deptName!="商户"&&!e.userName));
        this.searchData.searchItem.forEach(ele => {
          if (ele.value === 'deptId') {
            ele.options = this.departmentLists
          }
        })
      })
    },

    _getParams(){
      return {
        deptId:this.searchData.params.deptId,
        endDate:this.searchData.params.endTime,
        startDate:this.searchData.params.startTime,
        pageNum: this.pager.page,
        pageSize: this.pager.rows
      }
    },

    // 查询列表
    searchList(page){
      if(!this.$refs.timeSearch.validate()) return ;
      page && (this.pager.page = 1,this.pager.rows = 10)
      this.$api.mentapi.aprDepartmentList(this._getParams()).then(({data})=>{
        this.tableData = data.records;
        this.pager.count = data.total;
      })
    },

    // 导出
    exportList(){
      this.$api.mentapi.aprDepartmentExport({...this._getParams(),pageSize:10000,pageNum:1}).then(({headers,data})=>{
        onDownload(headers,data)
      })
    },

    reset(){
      this.$refs.timeSearch.reset();
      this.searchList()
    }
  }
}
</script>

<style scoped>

</style>